<template>
  <base-modal :title="$t('selectproductsforevent')" @close="close">
    <ion-content>
      <div class="container mx-auto">
        <Refresher @refresh="doRefresh($event, 'getOrderableProductsForEvents')" />
        <List
        :not-found-message="$t('noproductsfound')"
        :loading="$apollo.loading && !products"
        :loading-more="loadingMore"
        @loadMore="loadMore($event, 'getOrderableProductsForEvents')"
      >
        <template v-if="products && products.data">
          <ion-item type="button" v-for="product in products.data" class="cursor-pointer" :key="product.id" @click="showVariants(product)">
            <ion-card style="width: 100%">
              <ion-card-header>
                <ion-card-title color="white">
                  {{ product.name }}
                </ion-card-title>
                <ion-label v-if="facility_id !== product.facility_id">{{ $t('soldby', {facility_name: product.facility.name}) }}</ion-label>
              </ion-card-header>
              <ion-card-content>
                <ion-text color="moto">
                  <span v-if="product.productFamily.parent" class="mr-2">{{ product.productFamily.parent.name}} \ </span>{{ product.productFamily.name }}
                </ion-text>
                <ion-text color="white">
                  <p>{{ product.description}}</p>
                </ion-text>
              </ion-card-content>
              <template v-if="showingVariants(product)">
                <ion-list lines="full">
                  <ion-list-header>
                    <ion-label>{{ $t('productvariants') }}</ion-label>
                  </ion-list-header>
                  <ion-item
                    type="button"
                    class="cursor-pointer"
                    style="width: 100%"
                    v-for="variant in product.variants"
                    :key="variant.id"
                    @click="selectVariant(variant)"
                  >
                    <ion-card color="dark" style="width: 100%" >
                      <ion-card-header color="dark">
                        <ion-card-title>{{ variant.name }}</ion-card-title>
                      </ion-card-header>
                      <ion-card-content>
                        <ion-grid>
                          <ion-row>
                            <ion-col size="12" size-sm="8">
                              <ion-text>
                                <p>{{ variant.description }}</p>
                              </ion-text>
                            </ion-col>
                            <ion-col size="6" size-sm="2">
                              <ion-badge>{{ $t('instock') }}</ion-badge>
                            </ion-col>
                            <ion-col size="6" size-sm="2">
                              <ion-text color="moto">{{ variant.price | priceAmount }}</ion-text>
                            </ion-col>
                          </ion-row>
                        </ion-grid>
                      </ion-card-content>
                    </ion-card>
                  </ion-item>
                </ion-list>
              </template>

            </ion-card>
          </ion-item>
        </template>
      </List>
      </div>
    </ion-content>
    <ion-footer>
      <loading-button color="moto" :loading="false" :disabled="false" @click="close">
        <ion-label>{{ $t('close') }}</ion-label>
      </loading-button>
    </ion-footer>
  </base-modal>
</template>

<style scoped>
  .bg-darker {
    background-color: #333 !important;
  }
</style>

<script>
    import BaseModal from './BaseModal.vue';
    import { GET_ORDERABLE_PRODUCTS_FOR_EVENTS } from "@/graphql/queries"
    import { infiniteScrollMixin } from "@/mixins";
    import { defaultPaginationConfig } from "@/configs";

    export default {
      mixins: [infiniteScrollMixin],
      components: {
        BaseModal,
      },
      props: ['facility_id'],
      data() {
        return {
          productVariantsShown: []
        }
      },
      apollo: {
        getOrderableProductsForEvents: {
          query: GET_ORDERABLE_PRODUCTS_FOR_EVENTS,
          variables() {
            return {
              ...defaultPaginationConfig,
              facility_id: this.facility_id,
            }
          }
        }
      },
      computed: {
        products() {
          return this.getOrderableProductsForEvents;
        }
      },
      methods: {
        showingVariants(product) {
          return this.productVariantsShown.includes(product.id);
        },
        showVariants(product) {
          this.productVariantsShown.push(product.id);
        },
        async doRefresh(event, model) {
          this.resetInfiniteScroll();
          await this.$apollo.queries[model].refetch();
          event.target.complete()
        },
        close() {
          this.$ionic.modalController.dismiss(this);
        },
        selectVariant(variant) {
          this.$parent.$emit('selected-product-variant', variant);
          this.close();
        }
      }
    }
</script>
