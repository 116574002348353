<template>
  <base-modal :title="title" @close="close">
    <ion-content>
      <ion-list>
        <ion-item v-for="(option, i) in currentOptions" :key="i">
          <div slot="start">
            <ion-label position="stacked">{{ $t('option') }} {{ i + 1 }}</ion-label>
            <ion-input
              :value="option"
              @ionInput="updateOption(i, $event.target.value)"
              type="text" />
          </div>
          <div slot="end">
            <ion-label>
              <ion-button @click="deleteOption(i)" size="small">
                <ion-icon name="close" />
              </ion-button>
            </ion-label>
          </div>
        </ion-item>
      </ion-list>
      <ion-item>
        <ion-button @click="addOption" class="mt-1">
          <ion-icon name="add" class="mr-2" />
          {{ $t('addanoption') }}
        </ion-button>
      </ion-item>
    </ion-content>

    <ion-footer>
      <ion-button color="moto" @click="save" expand="full">
        <ion-text>{{ $t('confirm') }}</ion-text>
      </ion-button>
    </ion-footer>
  </base-modal>
</template>

<script>

  import BaseModal from "@/components/modals/BaseModal";
export default {
  components: {BaseModal},
  props: {
    options: {},
    onConfirm: {
      type: Function,
    },
    title: {
    }
  },
  data() {
    return {
      currentOptions: [...this.options],
    }
  },
  methods: {
    addOption() {
      this.currentOptions.push(this.$t('newoption'));
    },
    updateOption(idx, value) {
      this.currentOptions[idx] = value;
    },
    deleteOption(idx) {
      this.currentOptions.splice(idx, 1);
    },
    save() {
      this.onConfirm(this.currentOptions);
      this.close();
    },
    close() {
      this.$ionic.modalController.dismiss();
    }
  }
}
</script>
