<template>
  <ContentContainer>
    <div
      :style="{ backgroundImage: `url('${getBackgroundImage()}` }"
      class="relative z-0"
      style="height: 30vh; background-size: cover;"
    >
      <ion-button size="small" class="absolute bottom-0 z-10 right-0 change-foto" @click="openFile">
        <ion-icon name="camera" />
        {{ $t('changephoto') }}
      </ion-button>
      <ion-input ref="profile" type="file" accept="image/*" style="display:none;" @ionInput="fileUpload($event)" />
    </div>
    <ion-list lines="full">
      <ion-item>
        <ion-label position="stacked">
          {{ $t('eventname') }}*
        </ion-label>
        <ion-text v-if="!$v.create.name.required && $v.create.name.$dirty" class="text-sm" color="moto">
          <span>{{ $t('fieldisrequired') }}</span>
        </ion-text>

        <ion-input
          type="text"
          :placeholder="$t('name')"
          :value="create.name"
          data-cy="event-name"
          @ionBlur="$v.create.name.$touch()"
          @ionInput="create.name = $event.target.value; "
        />
      </ion-item>

      <ion-item>
        <ion-label position="stacked">
          {{ $t('description') }}*
        </ion-label>
        <ion-textarea
          :placeholder="$t('addadescription')"
          auto-grow="true"
          data-cy="description"
          :value="create.description"
          rows="4"
          required="true"
          @ionInput="create.description = $event.target.value;"
        />
      </ion-item>

      <ion-item v-if="selectedFacility && !event" button @click="selectOrganizer">
        <ion-thumbnail slot="end">
          <img :src="`${$apiStorageUrl}/${selectedFacility.profile_image}`">
        </ion-thumbnail>
        <ion-label>
          <span class="block text-sm mb-2">{{ $t('organizer') }}</span>
          <span>{{ selectedFacility.name }}</span>
        </ion-label>
      </ion-item>
      <ion-item v-else-if="selectedFacility">
        <ion-thumbnail slot="end">
          <img :src="`${$apiStorageUrl}/${selectedFacility.profile_image}`">
        </ion-thumbnail>
        <ion-label>
          <span class="block text-sm mb-2">{{ $t('organizer') }}</span>
          <span>{{ selectedFacility.name }}</span>
        </ion-label>
      </ion-item>

      <ion-item @click="selectHostFacility" button v-if="hostFacilityEnabled">
        <template v-if="hostFacility">
          <ion-button slot="end" @click.stop.prevent="create.host_facility_id = null">
            <ion-icon name="trash"></ion-icon>
          </ion-button>
          <ion-thumbnail slot="end">
            <img :src="`${$apiStorageUrl}/${hostFacility.profile_image}`">
          </ion-thumbnail>
          <ion-label>
            <span class="block text-sm mb-2">{{ $t('hostfacility') }} ({{ $t('optional') }})</span>
            <span>{{ hostFacility.name }}</span>
          </ion-label>
        </template>
        <template v-else>
          <ion-label>
            <span class="block text-sm mb-2">{{ $t('hostfacility') }} ({{ $t('optional') }})</span>
            <span>{{ $t('selecthostfacilityifotherthenorganizer') }}</span>
          </ion-label>
        </template>
      </ion-item>

      <ion-item button data-cy="event-location" @click="openPlaceModal()">
        <ion-label>
          <span class="block text-sm mb-2">{{ $t('location') }}*</span>
          <span v-if="location && location.name">{{ location.name }}</span>
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-list lines="full">
      <ion-item>
        <ion-label position="stacked">
          {{ $t('begintime') }}*
        </ion-label>
        <ion-datetime
          display-format="MMM DD, YYYY HH:mm"
          :min="minBeginDateTime"
          :max="maxBeginDateTime"
          :value="create.begin_time"
          data-cy="event-begindate"
          picker-format="DD MM YYYY HH:mm"
          @ionChange="changeBeginTime($event)"
        />
      </ion-item>

      <ion-item>
        <ion-label position="stacked">
          {{ $t('endtime') }}*
        </ion-label>
        <ion-datetime
          display-format="MMM DD, YYYY HH:mm"
          :min="minEndDateTime"
          :max="maxEndDateTime"
          :value="create.end_time"
          data-cy="event-enddate"
          picker-format="DD MM YYYY HH:mm"
          @ionChange="changeEndTime($event)"
        />
      </ion-item>

      <ion-item>
        <ion-label position="stacked">
          {{ $t('opentime') }}
        </ion-label>
        <ion-datetime
          display-format="MMM DD, YYYY HH:mm"
          :min="minOpenDateTime"
          :max="maxOpenDateTime"
          :value="create.open_time"
          data-cy="event-opendate"
          picker-format="DD MM YYYY HH:mm"
          @ionChange="changeOpenTime($event)"
        />
      </ion-item>

      <ion-item v-if="!event && eventTypeSupportsRepeating">
        <ion-label position="stacked">
          {{ $t('repeat') }}
        </ion-label>
        <ion-select
          :placeholder="$t('choosetype')"
          :value="create.repeated"
          @ionChange="changeRepeated($event.target.value)"
        >
          <ion-select-option value="NEVER">
            {{ $t('never') }}
          </ion-select-option>
          <ion-select-option value="WEEKLY">
            {{ $t('everyweek') }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item v-if="create.repeated != 'NEVER'" lines="none">
        <ion-label position="stacked">
          {{ $t('repeatingendsafter') }}
        </ion-label>
        <ion-input
          type="number"
          placeholder="20"
          :value="create.numberOfTimes"
          :min="1"
          :max="52"
          @ionChange="create.numberOfTimes = $event.target.value"
        />
      </ion-item>
    </ion-list>

    <ion-list v-if="create.type != 'OTHER'" lines="full">
      <ion-item>
        <ion-label position="stacked">
          {{ $t('suitablefor') }}*
        </ion-label>
        <ion-select
          :placeholder="$t('choosesport')"
          multiple
          data-cy="select-sport"
          @ionChange="selectClass($event.target.value)"
        >
          <ion-select-option v-for="sport in sportSelects" :key="sport.value" :value="sport.value" :selected="create.class.includes(sport.value)">
            {{ $t(sport.label) }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item v-if="create.class.includes('CROSS')">
        <ion-label position="stacked">
          {{ $t('forwhichlevelmotorcross') }}*
        </ion-label>
        <ion-select :placeholder="$t('chooselevel')" multiple @ionChange="select('create.level', $event.target.value)">
          <ion-select-option v-for="level in levelSelects" :key="level.value" :selected="create.level.includes(level.value)" :value="level.value">
            {{ $t(level.label) }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label>{{ $t('licensedfederationrequired') }}</ion-label>
        <ion-toggle :checked="create.needs_federation" @ionChange="toggleBond" />
      </ion-item>

      <ion-item v-if="create.needs_federation">
        <ion-label position="stacked">
          {{ $t('whichfederation') }}
        </ion-label>
        <ion-select :placeholder="$t('choosefederation')" multiple @ionChange="select('create.bond', $event.target.value)">
          <ion-select-option v-for="federation in federationSelects" :key="federation.value" :selected="create.bond.includes(federation.value)" :value="federation.value">
            {{ federation.label }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label>{{ $t('membershiprequired') }}</ion-label>
        <ion-toggle :checked="create.needs_membership" @ionChange="toggleMembership" />
      </ion-item>

      <ion-item>
        <ion-label position="stacked">
          {{ $t('trackstatus') }}
        </ion-label>
        <ion-select placeholder="Status circuit" multiple @ionChange="select('statusCircuit', $event.target.value)">
          <ion-select-option v-for="circuitSelect in circuitSelects" :key="circuitSelect.value" :value="circuitSelect.value" :selected="statusCircuit.includes(circuitSelect.value)">
            {{ $t(circuitSelect.label) }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <ion-list lines="full">
      <ion-item>
        <ion-label>{{ $t('firstaidpresent') }}</ion-label>
        <ion-toggle :checked="isEhbo" @ionChange="isEhbo = !isEhbo" />
      </ion-item>

      <ion-item v-if="eventTypeSupportsSellingTickets">
        <ion-label>
          {{ $t('sellingtrainingtickets') }}
        </ion-label>

        <ion-toggle :checked="sellTrainingTickets" :disabled="!canToggleTrainingTickets" @ionChange="toggleTrainingTickets" />
      </ion-item>
    </ion-list>

    <ion-list lines="full" v-if="this.eventType === EventTypeEnum.TRAINING">
      <ion-item>
        <ion-label>{{ $t('trainerpresent') }}</ion-label>
        <ion-toggle :checked="isTrainer" @ionChange="isTrainer = !isTrainer" />
      </ion-item>

      <ion-item>
        <ion-label>
          {{ $t('seperatedintimegroups') }}
        </ion-label>

        <ion-toggle :checked="trainInGroups" @ionChange="trainInGroups = !trainInGroups" />
      </ion-item>
    </ion-list>

    <div v-if="trainingTickets.length && sellTrainingTickets" class="mb-10">
      <TrainingTicketForm
        v-for="(trainingTicket, key) in trainingTickets"
        :key="key"
        :training-ticket="trainingTicket"
        :selected-facility="selectedFacility"
        :memberships-enabled="membershipsEnabled"
        :can-remove="trainingTickets.length > 1 && !trainingTicket.sold_tickets_count"
        @maxCount="setMaxCountForTicket(key, $event)"
        @maxTicketsPerTicket="setMaxTicketsPerTicket(key, $event)"
        @selectMembership="selectMembership(key, $event)"
        @onlyMembers="onlyMemberChange(key)"
        @remove="removeTraining(key)"
        @isRequired="isRequiredChange(key)"
      />

      <div class="text-center mb-10">
        <ion-button size="small" fill="outline" color="white" @click="addTrainingTicket">
          <ion-icon name="add" />
          {{ $t('addanothertrainingticket') }}
        </ion-button>
      </div>
    </div>

    <ion-list v-if="trainingTickets.length && sellTrainingTickets" lines="full">
      <ion-item>
        <ion-label position="stacked">
          {{ $t('totalcapacity') }}
        </ion-label>
        <ion-input
          type="number"
          placeholder="100"
          min="0"
          :value="totalCapacity"
          @ionChange="totalCapacity = $event.target.value"
        />
      </ion-item>
    </ion-list>

    <ion-list v-if="productVariantsForSale.length && sellProducts" class="mb-10" lines="full">
      <ProductForSaleForm
        v-for="(productForSale, key) in productVariantsForSale"
        :key="key"
        :memberships-enabled="membershipsEnabled"
        :product-for-sale="productForSale"
        :selected-facility="selectedFacility"
        :can-remove="!productForSale.sold_orders_count"
        @quantityAvailable="setQuantityAvailableForProduct(key, $event)"
        @maxProductsPerOrder="setMaxProductsPerOrder(key, $event)"
        @remove="removeProductVariantForSale(key)"
        @onlyMembers="productOnlyForMembersChange(key)"
        @selectMembership="selectProductMembership(key, $event)"
        @isRequired="productIsRequiredChange(key)"
      />
    </ion-list>

    <div class="text-center mb-10" v-if="sellProducts">
      <ion-button size="small" fill="outline" color="white" @click="openSelectOrderableProductForEventModal">
        <ion-icon name="add" />
        {{ $t('addproductforsale') }}
      </ion-button>
    </div>

    <div>
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col>
            <h1 class="text-xl">
              {{ $t('whatdatadoyouwanttocollect') }}
            </h1>
            <p class="mt-5 mb-10">
              {{ $t('selectbelowwhatdata') }}
            </p>
          </ion-col>
        </ion-row>

        <div v-for="field in fieldList" :key="field.key">
          <ion-row>
            <ion-col>
              {{ $t(field.label) }}
            </ion-col>
          </ion-row>
          <ion-row class="border-b" style="border-bottom-color: var(--ion-item-border-color);">
            <ion-col>
              <ion-label position="stacked" class="block">
                {{ $t('enable') }}
              </ion-label>
              <ion-toggle
                :checked="fields[field.key].toggled"
                class="toggle-small"
                @ionChange="fields[field.key].toggled = !fields[field.key].toggled"
              />
            </ion-col>

            <ion-col>
              <div v-if="fields[field.key].required != undefined">
                <ion-label position="stacked" class="block">
                  {{ $t('required') }}
                </ion-label>
                <ion-toggle
                  :checked="fields[field.key].required"
                  size="small"
                  @ionChange="fields[field.key].required = !fields[field.key].required"
                />
              </div>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
    </div>

    <EventFormAdditionalFields
      :additional_fields="additional_fields"
      @delete="deleteExtraField"
      @change="updateExtraFields"
    />

    <div class="text-center mb-10 mt-4">
      <ion-button size="small" fill="outline" color="white" @click="addExtraField">
        <ion-icon name="add" />
        {{ $t('addextrafield') }}
      </ion-button>
    </div>

    <ion-list v-if="eventType === EventTypeEnum.COMPETITION" lines="full">
      <ion-list-header>
        <ion-label>{{ $t('participatingevents') }}</ion-label>
      </ion-list-header>

      <template v-for="competitionEvent in competitionEvents">
        <ion-item-sliding :key="competitionEvent.id">
          <event-item :event="competitionEvent" :show-facility="true" :key="competitionEvent.id"/>
          <ion-item-options>
            <ion-item-option color="danger" @click="removeParticipatingEvent(competitionEvent)">
              <ion-icon name="trash" /> {{ $t('remove') }}
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </template>
    </ion-list>

    <div class="text-center mb-10 mt-4" v-if="eventType === EventTypeEnum.COMPETITION">
      <ion-button size="small" fill="outline" color="white" @click="chooseParticipatingEvents">
        <ion-icon name="add" />
        {{ $t('addparticipatingevent') }}
      </ion-button>
    </div>

    <ion-footer>
      <Error :error="error" />

      <ion-row>
        <ion-col>
          <LoadingButton :disabled="loading" :loading="loading" color="moto" data-cy="submit-event" @click="transformAndEmit">
            {{ event ? 'Update' : $t('tocreate') }}
          </LoadingButton>
        </ion-col>
      </ion-row>
    </ion-footer>
  </ContentContainer>
</template>

<script>
import {fieldsForm} from '@/components/inputs/field_mapping'
import {required} from 'vuelidate/lib/validators'
import SelectOrganizerModal from '@/components/modals/SelectOrganizerModal';
import * as moment from 'moment'
import PlaceModal from '@/components/modals/CreatePlaceModal.vue'
import { cloneDeep, get, set } from 'lodash';
import { APP_DEFAULTS, FEATURE_FLAG_MAPPING } from "@/configs";
import TrainingTicketForm from './TrainingTicketForm.vue';
import { mapGetters } from 'vuex';
import { CURRENT_USER_GETTER } from "@/store/store-getters";
import SelectOrderableProductForEvent from "@/components/modals/SelectOrderableProductForEvent.vue";
import ProductForSaleForm from "@/components/forms/ProductForSaleForm.vue";

const LEVELS = [
  {
    label: 'beginner',
    value: 'BEGINNER',
  },
  {
    label: 'recreational',
    value: 'RECREANT'
  },
  {
    label: 'advanced',
    value: 'COMPETITION',
  },
  {
    value: 'PROFESSIONAL',
    label: 'professional'
  }
]

const SPORTS = [
  {
    label: 'sidecars',
    value: 'SIDECAR'
  },
  {
    value: 'CROSS',
    label: 'motocross',
  },
  {
    label: 'trial',
    value: 'TRIAL'
  },
  {
    label: 'enduro',
    value: 'ENDURANCE'
  },
  {
    label: 'quads',
    value: 'QUADS'
  },
  {
    value: 'SUPERMOTO',
    label: 'supermoto'
  },
  {
    label: 'grasstrack',
    value: 'GRASS'
  }
]

const CIRCUIT_STATUSES = [
  {
    label: 'rutted',
    value: 'RUTTED'
  },
  {
    label: 'prepped',
    value: 'PREPPED'
  },
  {
    label: 'groomed',
    value: 'RIPPED',
  },
  {
    label: 'muddy',
    value: 'MUDDY'
  },
  {
    label: 'watered',
    value: 'WATERED',
  },
  {
    label: 'dusty',
    value: 'DUSTY',
  },
  {
    label: 'rough',
    value: 'ROUGH'
  }
];

const FEDERATIONS = [
  {
    label: 'KNMV',
    value: 'KNMV',
  },
  {
    label: 'MON',
    value: 'MON'
  }
]

const TRAINING_TICKET_TEMPLATE = {
  name: '',
  max_count: 0,
  max_tickets_per_ticket: APP_DEFAULTS.MAX_TICKETS_PER_TICKET,
  price: null,
  is_for_members: false,
  is_required: false,
  allowed_memberships: [],
  requirements: [],
}

import { showToast } from "@/utils/helpers";
import { EventTypeEnum } from "@/graphql/enums";
import EventItem from "@/components/items/EventItem";
import SelectFacilityEventsModal from "@/components/modals/SelectFacilityEventsModal";
import { GET_COMPETITION_EVENTS } from "@/graphql/queries";
import SelectHostFacilityModal from "@/components/modals/SelectHostFacilityModal";
import EventFormAdditionalFields from "@/components/forms/EventFormAdditionalFields";

export default {
  components: {
    EventFormAdditionalFields,
    EventItem,
    TrainingTicketForm,
    ProductForSaleForm
  },

  props: {
    loading: {
      required: true,
      type: Boolean,
    },
    error: {
      type: [ Error ],
      default: null
    },
    event: {
      default: null,
      required: false,
      type: Object
    },
    eventType: {
      default: null
    }
  },
  data() {
    return {
      EventTypeEnum,
      levelSelects: LEVELS,
      sportSelects: SPORTS,
      circuitSelects: CIRCUIT_STATUSES,
      federationSelects: FEDERATIONS,
      fields: fieldsForm,
      additional_fields: (this.event?.forms?.slice().pop()?.additional_fields) ? JSON.parse(this.event?.forms?.slice().pop()?.additional_fields) : [],
      totalCapacityIsUsed: false,
      totalCapacity: this.event?.total_capacity || 0,
      isEhbo: this.event?.first_aid_available || false,
      trainInGroups: this.event?.training_event?.training_in_groups || false,
      isTrainer: this.event?.training_event?.trainer_available || false,
      statusCircuit: !this.event?.track_conditions?.length ? ['PREPPED'] : [],
      sellTrainingTickets: true,
      tickets: [],
      location: null,
      file: null,
      selectedFacility: this?.event?.organizer || this.facility || null,
      image: this.event?.image ? `${this.$apiStorageUrl}/${this.event?.image}` : '',
      classInput: '',
      minOpenDateTime: '',
      maxOpenDateTime: '',
      minEndDateTime: '',
      maxEndDateTime: '',
      minBeginDateTime: '',
      maxBeginDateTime: '',
      create: {
        needs_federation: !!this?.event?.federations.length || false,
        needs_membership: false,
        end_time: this.event?.ends_at || '',
        description: this.event?.text || '',
        level: [],
        bond: [],
        membership: [],
        numberOfTimes: 0,
        ticketUrl: '',
        utc_begin_time: '',
        begin_time: this.event?.held_at || '',
        open_time: this.event?.opens_at || '',
        name: this.event?.title || '',
        type: this.event?.event_category?.name || this.eventType || EventTypeEnum.TRAINING,
        repeated: 'NEVER',
        class: [],
        host_facility_id: this.event?.host_facility_id,
      },
      competitionEvents: [],
      trainingTickets: [{...TRAINING_TICKET_TEMPLATE}],
      productVariantsForSale: [],
      apiStorageUrl: window.apiStorageUrl,
      selectOrderableProductForEventModal: null,
    }
  },
  validations: {
    create: {
      name: {
        required,
      },
      begin_time: {
        required
      }
    }
  },

  computed: {
    ...mapGetters([CURRENT_USER_GETTER]),
    hostFacilityEnabled() {
      return false;
    },
    hostFacility() {
      if (!this.create.host_facility_id) {
        return null;
      }
      if (!this.currentUser) {
        return null;
      }
      return this.currentUser.facilities.find( (facility) => facility.id === this.create.host_facility_id );
    },
    membershipsEnabled() {
      return this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.MANUAL_MEMBERSHIPS, this.currentUser.id, {
        user_id: this.currentUser.id,
      });
    },
    eventTypeSupportsRepeating() {
      return (this.eventType !== EventTypeEnum.COMPETITION);
    },
    eventTypeSupportsSellingTickets() {
      return (this.eventType !== EventTypeEnum.COMPETITION);
    },
    canToggleTrainingTickets() {
      return !this.event?.tickets?.length && this.eventTypeSupportsSellingTickets;
    },
    computedStatus() {
      return this.statusCircuit;
    },
    fieldList() {
      return Object.keys(this.fields).map(key => ({...this.fields[key], key: key}));
    },
    sellProducts() {
      const canSellProducts =  this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.CAN_SELL_PRODUCTS_AT_EVENTS, this.currentUser.id, {
        user_id: this.currentUser.id,
        facility_id: this.selectedFacility.id,
      });

      const isSellingProducts = !!(this.productVariantsForSale && this.productVariantsForSale.length);

      return canSellProducts || isSellingProducts;
    }

  },
  mounted() {
    this.$on('picked-location', (location) => {
      this.location = location;
    })

    this.$on('selected-organizer', (facility) => {
      this.selectedFacility = facility;
    });

    this.$on('selected-host-facility', (facility) => {
      this.create.host_facility_id = facility.id;
    });

    this.$on('selected-product-variant', (productVariant) => {
     this.addProductVariantForSale(productVariant);
    })
  },
  created() {
    this.minOpenDateTime = moment().toISOString(true);
    this.maxOpenDateTime = moment().add(1, 'year').toISOString(true);

    this.minBeginDateTime = moment().toISOString(true);
    this.maxBeginDateTime = moment().add(1, 'year').toISOString(true);

    this.minEndDateTime = moment().toISOString(true);
    this.maxEndDateTime = moment().add(1, 'year').toISOString(true);

    this.selectedFacility = this.getSelectedFacility();
    this.populateForm();
  },

  methods: {
    addProductVariantForSale(productVariant) {
      this.productVariantsForSale.push({
        id: null,
        product_variant_id: productVariant.id,
        productVariant: productVariant,
        quantity_available: 0,
        max_per_order: 1,
        requirements: [],
        is_for_members: false,
        is_required: false,
        allowed_memberships: [],
      });
    },
    changeRepeated(repeated) {
      this.create.repeated = repeated;
      switch (repeated) {
        case 'NEVER':
          this.create.numberOfTimes = 0;
          break;
        case 'WEEKLY':
          this.create.numberOfTimes = 52;
          break;
      }
    },
    removeParticipatingEvent(event) {
      const idx = this.competitionEvents.findIndex( (item) => item.id === event.id );
      if (idx === -1) {
        return;
      }
      this.competitionEvents.splice(idx, 1);
    },
    async chooseParticipatingEvents() {
      const choices = await this.chooseEvents();

      if (!choices || !choices.length) {
        return;
      }

      this.competitionEvents.push(...choices)
    },
    chooseEvents() {
      const excludedIds = this.competitionEvents.map( (event) => event.id);

      // Exclude ourselves
      if (this.event) {
        excludedIds.push(this.event.id);
      }
      return new Promise( async (resolve) => {
        const modal = await this.$ionic.modalController.create({
          component: SelectFacilityEventsModal,
          componentProps: {
            parent: this,
            propsData: {
              excludedIds,
              buttonLabel: this.$t('addtocompetition'),
              facility_id: this.selectedFacility.id,
              onSubmit: (selection) => {
                resolve(selection);
                modal.dismiss();
              },
            }
          }
        });
        modal.onDidDismiss().then( () => resolve(false) );
        await modal.present();
      })
    },
    removeProductVariantForSale(key) {
      this.productVariantsForSale.splice(key, 1);
    },
    productOnlyForMembersChange(key) {
      this.productVariantsForSale[key].is_for_members = !this.productVariantsForSale[key].is_for_members;

      this.$forceUpdate();
    },
    productIsRequiredChange(key) {
      this.productVariantsForSale[key].is_required = !this.productVariantsForSale[key].is_required;

      this.$forceUpdate();
    },
    showToast,
    getSelectedFacility() {
      if(this?.event?.organizer) {
        return this?.event?.organizer;
      }

      var facilityIds = this.currentUser.facilities.map(facility => facility.id);

      if (this.$route.params.facility && facilityIds.includes(this.$route.params.facility)) {
        return this.currentUser.facilities.find((facility) => facility.id == this.$route.params.facility);
      }

      return this.currentUser.facilities[0];
    },
    onlyMemberChange(key) {
      this.trainingTickets[key].is_for_members = !this.trainingTickets[key].is_for_members;

      this.$forceUpdate();
    },
    isRequiredChange(key) {
      this.trainingTickets[key].is_required = !this.trainingTickets[key].is_required;

      this.$forceUpdate();
    },
    updateExtraFields(fields) {
      this.additional_fields = [...fields]
    },
    deleteExtraField(key) {
      this.additional_fields.splice(key, 1)
    },
    addExtraField() {
      this.additional_fields.push({
        type: 'TEXT',
        label: '',
        required: false,
        options: null,
      })
    },
    transformTickets() {
      return (this.trainingTickets || []).map(ticket => {
        // eslint-disable-next-line no-unused-vars
        const { __typename, sold_tickets_count, quantity_available, ...restTicket } = ticket;

        if (restTicket.requirements) {
          restTicket.requirements = restTicket.requirements.map( (requirement) => {
            // eslint-disable-next-line no-unused-vars
            const { __typename, ...restRequirement } = requirement;

            return restRequirement;
          })
        }
        // Lighthouse5 has stricter validation, this needs to be an explicit float
        restTicket.price = (restTicket.price === null) ? null : parseFloat(restTicket.price);

        return restTicket
      });
    },
    transformProductsForSale() {
      return (this.productVariantsForSale || []).map(productForSale => {
        // eslint-disable-next-line no-unused-vars
        const { __typename, event_id, created_at, updated_at, productVariant, sold_orders_count, ...restProduct } = productForSale;

        return {
          ...restProduct
        }
      });
    },
    transformAndEmit() {
      const organizer_id = this.selectedFacility ? this.selectedFacility.id : this.facility.id;

      // eslint-disable-next-line no-unused-vars
      const { __typename, ...location } = this.location || {};

      const transformedData = {
        name: this.create.name,
        description: this.create.description,
        image: this.file,
        organizer_id,
        host_facility_id: this.create.host_facility_id,
        type: this.create.type,
        additional_fields: this.additional_fields,
        location: location,
        repeats: this.create.repeated,
        repeat_times: parseInt(this.create.numberOfTimes),
        open_time: this.create.open_time ? moment(this.create.open_time).format('YYYY-MM-DD HH:mm:ss') : null,
        begin_time: this.create.begin_time ? moment(this.create.begin_time).format('YYYY-MM-DD HH:mm:ss') : null,
        end_time: this.create.end_time ? moment(this.create.end_time).format('YYYY-MM-DD HH:mm:ss') : null,
        training: (this.eventType === EventTypeEnum.TRAINING) ? this.transformTrainingData() : null,
        competition: (this.eventType === EventTypeEnum.COMPETITION) ? this.transformCompetitionData() : null,
      };

      this.$emit('submit', transformedData);
    },
    transformTrainingData() {
      const tickets = this.transformTickets();
      const productsForSale = this.transformProductsForSale();

      return {
        needs_federation: this.create.needs_federation,
        needs_membership: this.create.needs_membership,
        status_circuits: this.statusCircuit,
        which_membership: this.create.membership,
        profile_fields: this.fields,
        has_training_tickets: this.sellTrainingTickets,
        total_capacity: parseInt(this.totalCapacity),
        training_tickets: tickets,
        which_federation: this.create.bond,
        suitable_for: this.create.class,
        which_level: this.create.level,
        first_aid_available: this.isEhbo,
        train_in_groups: this.trainInGroups,
        trainer_available: this.isTrainer,
        products_for_sale: productsForSale,
      }
    },
    transformCompetitionData() {
      const productsForSale = this.transformProductsForSale();

      return {
        needs_federation: this.create.needs_federation,
        needs_membership: this.create.needs_membership,
        status_circuits: this.statusCircuit,
        which_membership: this.create.membership,
        profile_fields: this.fields,
        has_training_tickets: this.sellTrainingTickets,
        total_capacity: parseInt(this.totalCapacity),
        which_federation: this.create.bond,
        suitable_for: this.create.class,
        which_level: this.create.level,
        first_aid_available: this.isEhbo,
        train_in_groups: this.trainInGroups,
        trainer_available: this.isTrainer,
        products_for_sale: productsForSale,
        competition_event_ids: this.competitionEvents.map( (event) => event.id ),
      }
    },
    populateForm() {
      this.populateSelects();
      this.populateLocation();
      this.populateTickets();
      this.populateCompetitionEvents();
      this.populateProductsForSale();
      this.populateFields();
      this.populateTimeFields();
    },
    async populateCompetitionEvents() {
      if (this.eventType !== EventTypeEnum.COMPETITION) {
        return;
      }
      if (!this.event?.id) {
        return;
      }
      const response = await this.$apollo.query({
        query: GET_COMPETITION_EVENTS,
        variables: {
          competition_event_id: this.event.id
        }
      })

      this.competitionEvents = response.data.getCompetitionEvents ? [...response.data.getCompetitionEvents] : [];
    },
    populateFields() {
      if(!this.event?.forms?.length) {
        return;
      }

      const form = this.event.forms.slice().pop();
      this.fields =  {...fieldsForm, ...JSON.parse(form.profile_fields)};
    },
    populateTimeFields() {
      if (this.event?.held_at) {
        this.changeBeginTime(this.event.held_at)
      }
      if (this.event?.ends_at) {
        this.changeEndTime(this.event.ends_at)
      }
      if (this.event?.opens_at) {
        this.changeOpenTime(this.event.opens_at)
      }
    },
    populateTickets() {
      if (!this.eventTypeSupportsSellingTickets) {
        this.sellTrainingTickets = false;
        this.trainingTickets = [];
      }

      if (!this.event) {
        return;
      }

      if(!this.event.tickets.length) {
        this.sellTrainingTickets = false;
        this.trainingTickets = [];
        return;
      }

      this.trainingTickets = this.event.tickets.map(ticket => {
        const { subscriptions, requirements, ...restTicket } = ticket;

        return {
          ...restTicket,
          requirements: requirements || [],
          allowed_memberships: subscriptions.map(subscription => subscription.id),
          max_count: ticket.quantity_available,
        }
      })
    },
    populateProductsForSale() {
      if (!this.event) {
        return;
      }

      if(!this.event.products_for_sale.length) {
        this.productVariantsForSale = [];
        return;
      }

      this.productVariantsForSale = this.event.products_for_sale.map(productVariant => {
        // eslint-disable-next-line no-unused-vars
        const { __typename, requirements, subscriptions, ...restProduct } = productVariant;

        return {
          ...restProduct,
          requirements: requirements || [],
          allowed_memberships: (subscriptions || []).map(subscription => subscription.id)
        }
      })
    },
    populateSelects() {
      const selectConfigs = [
        {
          modelKey: 'sports',
          dataKey: 'create.class',
          modelProperty: 'name',
        },
        {
          modelKey: 'levels',
          dataKey: 'create.level',
          modelProperty: 'name'
        },
        {
          modelKey: 'federations',
          dataKey: 'create.bond',
          modelProperty: 'system_name'
        },
        {
          modelKey: 'track_conditions',
          dataKey: 'statusCircuit',
          modelProperty: 'name'
        }
      ];

      selectConfigs.map(selectConfig => this.populateSelect(selectConfig));
    },
    populateSelect(selectConfig) {
      if (!this?.event?.[selectConfig.modelKey]?.length) {
        return;
      }

      this?.event?.[selectConfig.modelKey]?.map(model => get(this, selectConfig.dataKey).push(model[selectConfig.modelProperty]));
    },
    populateLocation() {
      if (!this?.event?.location) {
        return;
      }

      // eslint-disable-next-line no-unused-vars
      const { long, lat, ...location } = this?.event?.location;

      this.location = {
        ...location,
        latitude: this?.event?.location?.lat,
        longitude: this?.event?.location?.long,
      }
    },
    fileUpload(event) {
      this.file = event.target.getElementsByTagName('input')[0].files[0];

      this.image = this.file ? URL.createObjectURL(this.file) : undefined;
    },
    selectMembership(key, value) {
      const parsedValue = Array.isArray(value) ? value : value.split(',');

      // @todo: Why create a new object?
      this.trainingTickets[key] = {
        ...this.trainingTickets[key],
        allowed_memberships: [...parsedValue],
      }

    },
    selectProductMembership(key, value) {
      const parsedValue = Array.isArray(value) ? value : value.split(',');

      // @todo: Why create a new object?
      this.productVariantsForSale[key] = {
        ...this.productVariantsForSale[key],
        allowed_memberships: [...parsedValue],
      }
    },
    selectClass(value) {
      this.create.class = [
        ...value
      ];

      if (!this.create.class.includes('CROSS')) {
        this.create.level = []
      }
    },
    select(key, value) {
      set(this, key, [
          ...value
      ])
    },
    setMaxCountForTicket(key, value) {
      this.trainingTickets[key].max_count = this.valueToInt(value);
    },
    setMaxTicketsPerTicket(key, value) {
      this.trainingTickets[key].max_tickets_per_ticket = this.valueToInt(value)
    },
    setQuantityAvailableForProduct(key, value) {
      this.productVariantsForSale[key].quantity_available = this.valueToInt(value);
    },
    setMaxProductsPerOrder(key, value) {
      this.productVariantsForSale[key].max_per_order = this.valueToInt(value);
    },
    valueToInt(value) {
      return (value === '') ? 0 : parseInt(value);
    },
    openPlaceModal() {
      return this.$ionic.modalController
          .create({
            component: PlaceModal,
            componentProps: {
              parent: this,
            },
          })
          .then(m => m.present())
    },
    openSelectOrderableProductForEventModal() {
      if (!this.selectedFacility) {
        this.$ionic.alertController
          .create({
            header: this.$t('message'),
            message: this.$t('selectafacilityfirst'),
            buttons: [this.$t('ok')],
          })
          .then(a => a.present());
        return;
      }
      return this.$ionic.modalController
        .create({
          component: SelectOrderableProductForEvent,
          componentProps: {
            parent: this,
            propsData: {
              facility_id: this.selectedFacility?.id
            }
          },
        })
        .then(m => {
          this.selectOrderableProductForEventModal = m;
          m.present()
        })
    },
    changeOpenTime(event) {
      const value = event?.target ? event.target.value : event;

      this.create.open_time = value;
    },
    changeBeginTime(event) {
      const value = event?.target ? event.target.value : event;
      const beginTime = moment(value);

      this.create.begin_time = value;
      this.constrainToBeginTime(beginTime);
    },
    changeEndTime(event) {
      const value = event?.target ? event.target.value : event;

      this.create.end_time = moment(value).toISOString(true);
    },
    constrainToBeginTime(beginTime) {
      this.maxOpenDateTime = beginTime.toISOString(true);
      this.minEndDateTime = beginTime.toISOString(true);
      this.maxEndDateTime = beginTime.clone().add(1, 'y').toISOString(true);

      if (this.create.open_time && beginTime.isBefore(this.create.open_time)) {
        this.create.open_time = beginTime.toISOString(true);
        this.showToast(this.$t('opentimeconstrained'), 'warning', 6000);
      }

      if (this.create.end_time && beginTime.isAfter(this.create.end_time)) {
        this.create.end_time = beginTime.toISOString(true);
        this.showToast(this.$t('endtimeconstrained'), 'warning', 6000);
      }
    },
    toggleMembership() {
      this.create.needs_membership = !this.create.needs_membership

      if (!this.create.needs_membership) {
        this.create.membership = []
      }
    },
    toggleBond() {
      this.create.needs_federation = !this.create.needs_federation

      if (!this.create.needs_federation) {
        this.create.bond = []
      }
    },
    addTrainingTicket() {
      const trainingTicket = cloneDeep(TRAINING_TICKET_TEMPLATE);

      this.trainingTickets.push(trainingTicket)
    },
    toggleTrainingTickets() {
      if (!this.canToggleTrainingTickets) {
        return;
      }

      this.sellTrainingTickets = !this.sellTrainingTickets

      this.trainingTickets = [];
      if (this.sellTrainingTickets) {
        this.addTrainingTicket();
      }
    },
    async selectOrganizer() {
      if (this.currentUser.facilities.length <= 1) return;


      const modal = await this.$ionic.modalController
          .create({
            component: SelectOrganizerModal,
            componentProps: {
              parent: this,
              propsData: {
                type: this.create.type
              }
            },
          });

      modal.present()

    },
    async selectHostFacility() {
      // Sanity check
      if (!this.hostFacilityEnabled) {
        return;
      }

      if (this.currentUser.facilities.length <= 1) return;

      const modal = await this.$ionic.modalController
          .create({
            component: SelectHostFacilityModal,
            componentProps: {
              parent: this,
              propsData: {
                excludedIds: this.selectedFacility ? [this.selectedFacility.id] : []
              }
            },
          });

      modal.present()
    },
    async removeTraining(key) {
      if(this.trainingTickets[key].sold_tickets_count > 1) {
        const alert = await this.$ionic.toastController.create({
          message: "This ticket is already sold cannot delete this ticket",
          color: 'danger',
          duration: 3000,
        })

        await alert.present();
        return;
      }

      this.trainingTickets.splice(key, 1);
    },
    openFile() {
      this.$refs.profile.getElementsByTagName('input')[0].click()
    },
    getBackgroundImage() {
      if (this.image) {
        return this.image;
      }

      if (this.selectedFacility && this.selectedFacility.profile_image) {
        return `${this.$apiStorageUrl}/${this.selectedFacility.profile_image}`;
      }

      if (this.facility && this.facility.profile_image) {
        return `${this.$apiStorageUrl}/${this.facility.profile_image}`;
      }

      return ''
    },
  }
}
</script>

<style scoped>
.ticket-input {
  border-style: solid;
  border-width: 1px;
  border-color: var(--ion-item-border-color);
  --padding-start: 5px;
}

restProduction-avatar img {
  height: 100%;
}

ion-chip {
  padding: 0 10px;
}

.change-foto {
  --background: rgba(0, 0, 0, .5)
}

ion-toggle {
  zoom: 0.8;
}
</style>
