<template>
  <div class="text-center">
    <ion-label position="stacked" class="block">&nbsp;</ion-label>
    <ion-button
      button
      fill="outline"
      size="small"
      color="white"
      @click="editOptions"><ion-icon name="more" class="mr-1" />{{ $t('editoptions') }}</ion-button>
  </div>
</template>

<script>
import EditOptionsModal from "@/components/modals/EditOptionsModal";

export default {
  emits: ['change'],
  props: [
    'title',
    'options'
  ],
  methods: {
    showModal() {
      return new Promise(async (resolve) => {

        const modal = await this.$ionic.modalController
          .create({
            component: EditOptionsModal,
            componentProps: {
              parent: this,
              propsData: {
                title: this.title,
                options: this.options,
                onConfirm: (value) => resolve(value)
              },
            },
          });

        modal.onDidDismiss().then(() => resolve(false));

        await modal.present();
      });
    },
    async editOptions() {
      const result = await this.showModal();
      if (result !== false) {
        this.$emit('change', result);
      }
    },
  }
}

</script>