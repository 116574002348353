<template>
  <base-modal :title="$t('createplacesearch', { place: search })" @close="close">
    <template v-slot:header>
      <ion-toolbar>
        <ion-searchbar
          ref="searchbar"
          :placeholder="$t('searchalocation')"
          @keyup.enter="geocode"
          @ionInput="onInput($event.target.value)"
          @ionClear="onClear"
        />
        <ion-button
          :disabled="!(searchValue && inputChanged)"
          slot="end"
          class="mr-3"
          size="small"
          :color="!(searchValue && inputChanged) ? 'secondary' : 'moto'"
          @click="geocode"
        >{{ $t('search') }}</ion-button>
      </ion-toolbar>
    </template>
    <ion-content class="ion-padding">
      <ion-list>
        <ion-item button v-for="(address, key) in locations" :key="key" @click="selectLocation(address)">
          {{ address.name }}
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-footer>
      <Error class="ion-padding" :error="error" />
      <ion-text v-if="hasSearched" class="attribution"><p class="text-center ion-padding"><small>{{ $t('openstreetmapattribution') }}</small></p></ion-text>
    </ion-footer>
  </base-modal>
</template>

<style scoped>
  .attribution {
    text-align: center;
    color: #aaaaaa;
  }
</style>
<script>
import getLocation from '@/mixins/getLocation';
import BaseModal from './BaseModal.vue';
import { GEOCODE } from "@/graphql/queries";

  export default {
    components: {
      BaseModal,
    },
    name: 'Modal',
    mixins: [getLocation],
    props: {
      search: { type: String },
      closeMe: { type: Function, default: () => {} },
    },
    data() {
      return {
        hasSearched: false,
        error: null,
        name: '',
        locations: [],
        searchValue: '',
        inputChanged: false,
      }
    },
    created() {
      this.name = this.search
    },
    mounted() {
      // Focus the searchbar after a short delay
      setTimeout( () => {
        this.$refs.searchbar.setFocus();
      }, 200);
    },
    methods: {
      close() {
        this.$ionic.modalController.dismiss()
      },
      onClear() {
        this.searchValue = '';
        this.inputChanged = false;
      },
      onInput(value) {
        this.searchValue = value;
        this.inputChanged = true;
      },
      async geocode() {
        this.error = null;
        this.hasSearched = true;

        if(this.searchValue.length <= 2) {
          this.inputChanged = false;
          return;
        }

        try {
          const response = await this.$apollo.query({
            query: GEOCODE,
            fetchPolicy: 'cache-first',
            variables: {
              q: this.searchValue
            },
          });

          this.inputChanged = false;
          this.locations = response.data.geocode

        } catch (e) {

          if (e.graphQLErrors) {
            // eslint-disable-next-line no-undef
            const error = e.graphQLErrors[0];
            if (this.isRateLimitError(error)) {
              this.error = this.$t('errorratelimited')
            } else {
              this.error = error
            }
          } else {
            this.error = e
          }
          return;
        }
      },
      isRateLimitError(error) {
        return (error.extensions?.category === 'rate-limit');
      },
      selectLocation(address) {
        const location = {
            name: address.name,
            latitude: address.lat,
            longitude: address.long,
            city: address.city,
            street: address.street,
            state: address.state,
            country: address.country,
            number: address.number,
            zip_code: address.zip_code,
        }

        this.$parent.$emit('picked-location', location);
        this.close()
      },
    }
  }
</script>
