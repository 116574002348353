<template>
  <ion-grid v-if="trainingTicket">
    <ion-row v-if="trainingTicket.sold_tickets_count !== undefined">
      <ion-col>
        <ion-label color="moto">
          {{ $t('soldtickets') }}:&nbsp;
          <ion-text color="white">
            {{ trainingTicket.sold_tickets_count }}
          </ion-text>
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-label position="stacked" color="white">
          {{ $t('name') }}*
        </ion-label>
        <ion-input
          type="text"
          :placeholder="$t('name')"
          data-cy="event-ticket-name"
          class="ticket-input"
          :value="trainingTicket.name"
          @ionChange="trainingTicket.name = $event.target.value"
        />
      </ion-col>

      <ion-col>
        <ion-label position="stacked" color="white">
          {{ $t('maxcount') }}*
        </ion-label>
        <ion-input
          type="number"
          class="ticket-input"
          min="1"
          data-cy="event-ticket-count"
          :value="trainingTicket.max_count"
          :placeholder="$t('maxcount')"
          @ionChange="$emit('maxCount', $event.target.value)"
        />
      </ion-col>

      <ion-col>
        <ion-label position="stacked" color="white">
          {{ $t('maxticketsperticket') }}*
        </ion-label>
        <ion-input
          type="number"
          class="ticket-input"
          min="1"
          max="10"
          data-cy="event-ticket-count"
          :value="trainingTicket.max_tickets_per_ticket"
          :placeholder="$t('maxticketsperticket')"
          @ionChange="$emit('maxTicketsPerTicket', $event.target.value)"
        />
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-label position="stacked" color="white">
          {{ $t('price') }}*
        </ion-label>
        <ion-input
          type="number"
          class="ticket-input"
          :placeholder="$t('price')"
          data-cy="event-ticket-price"
          :value="trainingTicket.price"
          @ionChange="trainingTicket.price = parseFloat($event.target.value)"
        />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
          <OrderableRequirements
            :selected-facility="selectedFacility"
            :is-for-members="trainingTicket.is_for_members"
            :is-required="trainingTicket.is_required"
            :allowed-memberships="trainingTicket.allowed_memberships"
            :memberships-enabled="membershipsEnabled"
            :requirements="trainingTicket.requirements"
            @onlyMembers="$emit('onlyMembers')"
            @selectMembership="$emit('selectMembership', $event)"
            @isRequired="$emit('isRequired')"
            @removeRequirement="removeTicketRequirement($event)"
            @addRequirement="addRequirement($event)"
          />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button v-if="canRemove" fill="clear" @click="$emit('remove')">
          <ion-icon name="trash" />
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>

  import OrderableRequirements from "@/components/forms/OrderableRequirements.vue";

  export default {
    components: {OrderableRequirements},
    props: {
      trainingTicket: {
        type: Object
      },
      selectedFacility: {
        type: Object
      },
      membershipsEnabled: {
        type: Boolean
      },
      canRemove: {
        type: Boolean
      },
    },
    computed: {
      requirements() {
        return this.trainingTicket.requirements;
      }
    },
    methods: {
      removeTicketRequirement(index) {
        this.trainingTicket.requirements.splice(index, 1);
      },
      addRequirement(requirement) {
        this.trainingTicket.requirements.push({...requirement})
      },
    }
  }
</script>
