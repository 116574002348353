<template>
  <ion-list v-if="fields.length" lines="full">
    <ion-list-header>
      <ion-label>{{ $t('extrafields') }}</ion-label>
    </ion-list-header>

    <div v-for="(additional_field, key) in fields" :key="key" class="border-b" style="border-bottom-color: var(--ion-item-border-color);">
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-label position="stacked" color="white">
              {{ $t('label') }}
            </ion-label>
            <ion-input
              :value="additional_field.label"
              type="text"
              :placeholder="$t('enterthenameofthefield')"
              @ionInput="updateFieldLabel(key, $event.target.value)"
            />
          </ion-col>
          <ion-col class="text-right">
            <ion-button @click="$emit('delete', key)">
              <ion-icon name="trash" />
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-label position="stacked" color="white" class="block">
              {{ $t('type')}}
            </ion-label>
            <ion-select :value="additional_field.type" @ionChange="onFieldTypeChanged(key, $event.target.value)">
              <ion-select-option value="TEXT">{{ $t('text') }}</ion-select-option>
              <ion-select-option value="SELECT">{{ $t('select') }}</ion-select-option>
              <ion-select-option value="MULTISELECT">{{ $t('multiselect') }}</ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col v-if="['SELECT', 'MULTISELECT'].includes(additional_field.type)">
            <ExtraFieldOptions
              :title="additional_field.label"
              :options="additional_field.options"
              @change="updateFieldOptions(key, $event)"
            />
          </ion-col>
          <ion-col>
            <ion-label position="stacked" class="block">
              {{ $t('required') }}
            </ion-label>
            <ion-toggle
              :checked="additional_field.required"
              size="small"
              @ionChange="updateFieldRequired(key, $event.target.checked)"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-list>
</template>

<script>
  import ExtraFieldOptions from "@/components/inputs-edit/ExtraFieldOptions";
  export default {
    components: {ExtraFieldOptions},
    emits: ['delete', 'change'],
    props: {
      additional_fields: {}
    },
    data() {
      return {
        fields: this.parseFields(this.additional_fields),
      }
    },
    watch: {
      additional_fields(newValue) {
        this.fields = this.parseFields(newValue)
      },
    },
    methods: {
      parseFields(newFields) {
        const mapped = newFields.map( (f) => {
          const nf = Object.assign({}, f);
          if (!nf.options) {
            nf.options = [];
          }
          return nf;
        })

        return mapped
      },
      updateFields() {
        this.$emit('change', this.fields)
      },
      updateFieldRequired(key, value) {
        this.fields[key].required = value;
        this.updateFields();
      },
      updateFieldLabel(key, value) {
        this.fields[key].label = value
        this.updateFields();
      },
      updateFieldOptions(key, options) {
        this.fields[key].options = options;
        this.updateFields();
      },
      onFieldTypeChanged(key, type) {
        const field = this.fields[key];
        field.type = type;

        switch (type) {
          case 'SELECT':
          case 'MULTISELECT':
            if (!field.options) {
              this.$set(field, 'options', [])
            }
            break;
            default:
              // Clear the options
              if (field.options) {
                this.$set(field, 'options', [])
              }
              break;
        }

        this.updateFields();
      }
    }
  }
</script>