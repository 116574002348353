<template>
  <ion-grid>

    <ion-row>
      <ion-col>
        <ion-label color="moto">{{ $t('requirements') }}:</ion-label>
      </ion-col>
    </ion-row>
    <ion-row v-if="canAddMoreRequirements">
      <ion-col>
        <div class="text-left mb-10">
          <ion-button size="small" fill="outline" color="white" @click="addRequirement">
            <ion-icon name="add" />
            {{ $t('addrequirement') }}
          </ion-button>
        </div>
      </ion-col>
    </ion-row>

    <template v-for="(requirement, index) in requirements">
      <ion-row v-if="requirement.type === Requirements.BETWEEN_AGE" :key="index">
        <ion-col>
          <ion-label color="white" position="stacked">
            {{ $t('minimumage') }}
          </ion-label>
          <ion-input
            type="number"
            :placeholder="$t('minimumage')"
            :value="requirement.params.min"
            :min="0"
            :max="200"
            @ionChange="requirement.params.min = parseInt($event.target.value)"
          />
        </ion-col>
        <ion-col>
          <ion-label color="white" position="stacked">
            {{ $t('maximumage') }}
          </ion-label>
          <ion-input
            type="number"
            :placeholder="$t('maximumage')"
            :value="requirement.params.max"
            :min="requirement.params.min || 1"
            :max="200"
            @ionChange="requirement.params.max = parseInt($event.target.value)"
          />
        </ion-col>
        <ion-col class="text-right">
          <ion-button fill="clear" @click="$emit('removeRequirement', index)">
            <ion-icon name="trash" />
          </ion-button>
        </ion-col>
      </ion-row>
    </template>

    <ion-item
      v-if="selectedFacility && selectedFacility.enabled_subscriptions && selectedFacility.enabled_subscriptions.length && membershipsEnabled">
      <ion-label color="white">{{ $t('isonlyformembersquestion') }}</ion-label>
      <ion-toggle
        :checked="isForMembers"
        class="toggle-small"
        @ionChange="$emit('onlyMembers')"
      />
    </ion-item>

    <ion-item
      v-if="isForMembers && selectedFacility.enabled_subscriptions.length && membershipsEnabled">
      <ion-label color="white">{{ $t('allowedmemberships') }}</ion-label>
      <ion-select multiple @ionChange="$emit('selectMembership', $event.target.value)">
        <ion-select-option
          v-for="subscription in selectedFacility.enabled_subscriptions"
          :key="subscription.id"
          :value="subscription.id"
          :selected="allowedMemberships.includes(subscription.id)"
        >{{ subscription.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-label color="white">{{ $t('itemisrequiredquestion') }}</ion-label>
      <ion-toggle
        :checked="isRequired"
        class="toggle-small"
        @ionChange="$emit('isRequired')"
      />
    </ion-item>
  </ion-grid>
</template>

<script>
  import { OrderableRequirementType, REQUIREMENT_BETWEEN_AGE } from "@/graphql/enums";
  import { cloneDeep } from "lodash";

  const ageRequirement = {
    type: REQUIREMENT_BETWEEN_AGE,
    params: {
      min: 18,
      max: 65
    }
  };

  export default {
    props: [
      'selectedFacility',
      'isForMembers',
      'isRequired',
      'allowedMemberships',
      'membershipsEnabled',
      'requirements',
    ],
    data() {
      return {
        Requirements: OrderableRequirementType
      }
    },
    computed: {
      // At first, we only allow requirements on the rider age, so only (1) requirement can ever apply
      canAddMoreRequirements() {
        return !this.requirements?.length;
      },

    },
    methods: {
      addRequirement() {
        this.$emit('addRequirement', cloneDeep(ageRequirement));
      }
    }

  }
</script>
