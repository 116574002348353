export const fieldsForm = {
  gender: {
      label: 'gender',
      toggled: false,
      required: false,
  },
  firstname: {
      label: 'firstname',
      toggled: true,
      required: true,
  },
  lastname: {
      label: 'lastname',
      toggled: true,
      required: true,
  },
  email: {
    label: 'email',
      toggled: true,
      required: true,
  },

  address: {
      label: 'address',
      toggled: false,
      required: false,
  },
  mobile: {
      label: 'mobilenumber',
      toggled: false,
      required: false,
  },
  telephone: {
      label: 'phonenumber',
      toggled: false,
      required: false,
  },
  nationality: {
      label: 'nationality',
      toggled: false,
      required: false,
  },
  which_bike: {
    label: 'whichbike',
      toggled: false,
      required: false,
  },
  has_ehbo: {
    label: 'hasehbo',
      toggled: false,
      required: false,
  },
  bsn: {
      label: 'bsnnumber',
      toggled: false,
      required: false,
  },
  start_number: {
    label: 'preferredstartnumber',
      toggled: false,
      required: false,
  },
  date_of_birth: {
      label: 'dateofbirth',
      toggled: true,
      required: true,
  },
  which_federation: {
      label: 'whichfederationcollectlabel',
      toggled: false,
      required: false,
  },
  which_sport: {
      label: 'whichsport',
      toggled: false,
      required: false,
  },
  doctor: {
      label: 'doctorinformation',
      toggled: false,
      required: false,
  },
  transponder: {
      label: 'transpondernumber',
      toggled: false,
      required: false,
  },
  allergies: {
      label: 'allergies',
      required: false,
      toggled: false,
  },
  blood_type: {
    label: "bloodtype",
    required: false,
    toggled: false
  },
  emergency: {
    label: 'emergencyinformation',
      toggled: false,
      required: false,
  },
  license_number_federation: {
    label: 'licensenumberfederation',
    toggled: false,
    required: false
  },
  license_plate_number: {
    label: 'licenseplatenumber',
    toggled: false,
    required: false,
  },
  driver_license_number: {
    label: 'driverlicensenumber',
    toggled: false,
    required: false,
  }
}

export const fieldsFormPresentation = {
  gender: {
    fields: [
      {
        type: "gender",
        label: "gender",
        userKey: "gender",
      }
    ]
  },
  firstname: {
    fields: [
      {
        type: "text",
        label: "firstname",
        userKey: "first_name"
      }
    ]
  },
  lastname: {
    fields: [
      {
        type: "text",
        label: "lastname",
        userKey: "last_name"
      }
    ]
  },
  email: {
    fields: [
      {
        type: "text",
        label: "email",
        userKey: "email"
      }
    ]
  },
  address: {
    fields: [
      {
        type: "text",
        label: "address",
        userKey: "address"
      }
    ]
  },
  mobile: {
    fields: [
      {
        label: "mobilenumber",
        type: "text",
        userKey: "mobile"
      }
    ]
  },
  telephone: {
    fields: [
      {
        label: "phonenumber",
        type: "text",
        userKey: "telephone"
      }
    ]
  },
  nationality: {
    fields: [
      {
        label: "nationality",
        type: "nationalities",
        userKey: 'nationality'
      }
    ]
  },
  which_bike: {
    fields: [
      {
        label: "bikeinputlabel",
        type: "bike",
        userKey: "bikes",
      }
    ]
  },
  has_ehbo: {
    fields: [
      {
        label: "hasehboinputlabel",
        type: "toggle",
        userKey: "has_ehbo"
      }
    ]
  },
  bsn: {
    fields: [
      {
        label: "bsnnumber",
        type: "text",
        userKey: "bsn"
      }
    ]
  },
  start_number: {
    fields: [
      {
        label: "preferredstartnumber",
        type: "text",
        userKey: "start_number"
      }
    ]
  },
  date_of_birth: {
    fields: [
      {
        label: "dateofbirth",
        type: "date",
        userKey: "date_of_birth"
      }
    ]
  },
  which_federation: {
    fields: [
      {
        label: "whichfederation",
        radioLabel: "yourfederations",
        selectLabel: "addnewfederation",
        type: "multi-select",
        renderType: 'federations',
        userKey: 'federations'
      }
    ]
  },
  which_sport: {
    fields: [
      {
        label: "whichsport",
        radioLabel: "yoursports",
        selectLabel: "addnewsport",
        type: "multi-select",
        renderType: 'sports',
        userKey: 'sports'
      }
    ]
  },
  doctor: {
    fields: [
      {
        label: "doctorinformation",
        type: "doctor"
      }
    ]
  },
  emergency: {
    fields: [
      {
        label: "emergencyinformation",
        type: "emergency"
      }
    ]
  },
  blood_type: {
    fields: [
      {
        label: "bloodtype",
        type: "bloodtype",
        userKey: "blood_type"
      }
    ]
  },
  transponder: {
    fields: [
      {
        label: "transpondernumber",
        type: "text",
        userKey: 'transponder_number'
      }
    ]
  },

  allergies: {
    fields: [
      {
        label: "allergies",
        type: "text",
        userKey: 'allergies'
      }
    ]
  },
  license_number_federation: {
    fields: [
      {
        label: 'licensenumberfederation',
        type: 'text',
        userKey: 'license_number_federation',
      }
    ]
  },
  license_plate_number: {
    fields: [
      {
        label: "licenseplatenumber",
        type: "text",
        userKey: 'license_plate_number'
      }
    ]
  },
  driver_license_number: {
    fields: [
      {
        label: 'driverlicensenumber',
        type: 'text',
        userKey: 'driver_license_number'
      }
    ]
  }
}

export const userFormPresentation = {
  gender: {
    fields: [
      {
        type: "gender",
        label: "gender",
        userKey: "gender",
      }
    ]
  },
  firstname: {
    fields: [
      {
        type: "text",
        label: "firstname",
        userKey: "first_name"
      }
    ]
  },
  lastname: {
    fields: [
      {
        type: "text",
        label: "lastname",
        userKey: "last_name"
      }
    ]
  },
  // email: {
  //   fields: [
  //     {
  //       type: "text",
  //       label: "email",
  //       userKey: "email"
  //     }
  //   ]
  // },
  address: {
    fields: [
      {
        type: "text",
        label: "address",
        userKey: "address"
      }
    ]
  },
  mobile: {
    fields: [
      {
        label: "mobilenumber",
        type: "text",
        userKey: "mobile"
      }
    ]
  },
  telephone: {
    fields: [
      {
        label: "phonenumber",
        type: "text",
        userKey: "telephone"
      }
    ]
  },
  nationality: {
    fields: [
      {
        label: "nationality",
        type: "nationalities",
        userKey: 'nationality'
      }
    ]
  },
  // which_bike: {
  //   fields: [
  //     {
  //       label: "bikeinputlabel",
  //       type: "bike",
  //       userKey: "bikes",
  //     }
  //   ]
  // },
  has_ehbo: {
    fields: [
      {
        label: "hasehboinputlabel",
        type: "toggle",
        userKey: "has_ehbo"
      }
    ]
  },
  bsn: {
    fields: [
      {
        label: "bsnnumber",
        type: "text",
        userKey: "bsn"
      }
    ]
  },
  start_number: {
    fields: [
      {
        label: "preferredstartnumber",
        type: "text",
        userKey: "start_number"
      }
    ]
  },
  date_of_birth: {
    fields: [
      {
        label: "dateofbirth",
        type: "date",
        userKey: "date_of_birth"
      }
    ]
  },
  which_federation: {
    fields: [
      {
        label: "whichfederation",
        radioLabel: "yourfederations",
        selectLabel: "addnewfederation",
        type: "multi-select",
        renderType: 'federations',
        userKey: 'federations'
      }
    ]
  },
  which_sport: {
    fields: [
      {
        label: "whichsport",
        radioLabel: "yoursports",
        selectLabel: "addnewsport",
        type: "multi-select",
        renderType: 'sports',
        userKey: 'sports'
      }
    ]
  },
  doctor: {
    fields: [
      {
        label: "doctorinformation",
        type: "doctor"
      }
    ]
  },
  contact_person: {
    fields: [
      {
        label: "emergencyinformation",
        type: "emergency",
      }
    ]
  },
  blood_type: {
    fields: [
      {
        label: "bloodtype",
        type: "bloodtype",
        userKey: "blood_type"
      }
    ]
  },
  transponder: {
    fields: [
      {
        label: "transpondernumber",
        type: "text",
        userKey: 'transponder_number'
      }
    ]
  },

  allergies: {
    fields: [
      {
        label: "allergies",
        type: "text",
        userKey: 'allergies'
      }
    ]
  },
  license_number_federation: {
    fields: [
      {
        label: 'licensenumberfederation',
        type: 'text',
        userKey: 'license_number_federation',
      }
    ]
  },
  license_plate_number: {
    fields: [
      {
        label: "licenseplatenumber",
        type: "text",
        userKey: 'license_plate_number'
      }
    ]
  },
  driver_license_number: {
    fields: [
      {
        label: 'driverlicensenumber',
        type: 'text',
        userKey: 'driver_license_number'
      }
    ]
  }
}
