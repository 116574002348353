<template>
  <base-modal :title="$t('selectorganizer')" @close="close">

    <ion-content>
      <ion-list v-if="user && user.facilities && user.facilities.length">
        <ion-item v-for="facility in user.facilities" :key="facility.id" button @click="selectOrganizer(facility)">
          <ion-thumbnail slot="start">
            <img :src="`${$apiStorageUrl}/${facility.profile_image}`">
          </ion-thumbnail>
          <ion-label>{{ facility.name }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </base-modal>
</template>

<script>
    import BaseModal from './BaseModal.vue';
    import { mapGetters } from "vuex";
    import { CURRENT_USER_GETTER } from "@/store/store-getters";

    export default {
      components: {
        BaseModal,
      },
      computed: {
        ...mapGetters({ user: CURRENT_USER_GETTER }),
      },
      methods: {
        close() {
          this.$ionic.modalController.dismiss();
        },
        selectOrganizer(facility) {
          this.$parent.$emit('selected-organizer', facility)
          this.close();
        }
      }
    }
</script>
