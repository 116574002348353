<template>
  <base-modal :title="$t('selecthostfacility')" @close="close">

    <ion-content>
      <ion-list v-if="facilities.length">
        <ion-item v-for="facility in facilities" :key="facility.id" button @click="selectHostFacility(facility)">
          <ion-thumbnail slot="start">
            <img :src="`${$apiStorageUrl}/${facility.profile_image}`">
          </ion-thumbnail>
          <ion-label>{{ facility.name }}</ion-label>
        </ion-item>
      </ion-list>
      <ion-item v-else>
        <ion-text>{{ $t('nofacilitiesfound') }}</ion-text>
      </ion-item>
    </ion-content>
  </base-modal>
</template>

<script>
    import BaseModal from './BaseModal.vue';
    import { mapGetters } from "vuex";
    import { CURRENT_USER_GETTER } from "@/store/store-getters";

    export default {
      components: {
        BaseModal,
      },
      props: {
        excludedIds: {
          default: []
        }
      },
      computed: {
        ...mapGetters({ user: CURRENT_USER_GETTER }),
        facilities() {
          if (!this.user?.facilities?.length) {
            return []
          }
          return this.user.facilities.filter( (facility) => {
            return !this.excludedIds.includes(facility.id);
          })
        }
      },
      methods: {
        close() {
          this.$ionic.modalController.dismiss();
        },
        selectHostFacility(facility) {
          this.$parent.$emit('selected-host-facility', facility)
          this.close();
        }
      }
    }
</script>
